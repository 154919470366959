import React from 'react';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';
import { Link } from 'react-router-dom';

export const Offline = () => {
    useSetResolved();
    return (
        <>
            <p>It looks like you're offline - so some pages are not available.</p>
            <p>
                <Link to="/">Go to the homepage</Link>
            </p>
        </>
    );
};
