import React, { useMemo, useRef } from 'react';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/useSetResolved';
import { CmsLayoutOneColumn } from 'src/components/CmsPage/CmsLayoutOneColumn';

import { Wrapper, Container } from '../Layout';

import { CmsLayoutDefault } from './CmsLayoutDefault';
import { CmsLayoutSidebar } from './CmsLayoutSidebar';

export type CmsPageInnerProps = {
    title: string | null;
    content_heading: string | null;
    content: string;
    page_layout: string | CmsLayout;
};

export enum CmsLayout {
    OneColumn = '1column',
    TwoColumnsLeft = '2columns-left',
    TwoColumnsRight = '2columns-right',
}

export const CmsPageInner: React.FC<CmsPageInnerProps> = React.memo((props) => {
    const cmsRef = useRef<HTMLDivElement>(null);

    useSetResolved();

    const inner = useMemo(() => {
        switch (props.page_layout) {
            case CmsLayout.TwoColumnsRight:
            case CmsLayout.TwoColumnsLeft: {
                return <CmsLayoutSidebar {...props} />;
            }
            case CmsLayout.OneColumn: {
                return <CmsLayoutOneColumn {...props} />;
            }
            default: {
                return <CmsLayoutDefault {...props} />;
            }
        }
    }, [props]);

    return (
        <Wrapper variant="page-inner">
            <Container>
                <div data-test-id="CmsPageInner" ref={cmsRef}>
                    {inner}
                </div>
            </Container>
        </Wrapper>
    );
});
