import React from 'react';
import { CmsPageQuery } from 'src/components/CmsPage/CmsPageQuery';

import '../../scss/_root-component.scss';

type CmsPageProps = {
    id: number | null;
};

export const CmsPage: React.FC<CmsPageProps> = (props) => {
    return <CmsPageQuery id={props.id} />;
};

export default CmsPage;
