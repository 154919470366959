import React from 'react';
import { PageTitle } from 'src/components/Layout/PageTitle';

import { TwoCol } from '../Layout';
import { RichText } from '../RichText/RichText';

import classes from './CmsLayout.scss';
import { CmsPageInnerProps } from './CmsPageInner';

export const CmsLayoutOneColumn: React.FC<CmsPageInnerProps> = (props) => {
    return (
        <>
            {props.content_heading && <PageTitle title={props.content_heading} />}
            <div className={classes.container} data-test-id="CmsPageInnerDefault">
                <TwoCol main={<RichText content={props.content} />} />
            </div>
        </>
    );
};
