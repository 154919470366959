import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/react-hooks';
import { cmsPage } from 'src/queries/__generated__/cmsPage';
import { NotFound } from 'src/components/NotFound/NotFound';
import { useDeps } from 'src/hooks/useDeps';

import getCmsPage from '../../queries/getCmsPage.graphql';
import { Spinner } from '../Layout/Spinner';
import { ErrorOrOfflineConnected } from '../Error/ErrorOrOffline';

import { CmsLayout, CmsPageInner } from './CmsPageInner';

type CmsPageProps = {
    id: number | null;
    page_layout?: CmsLayout;
};

export const CmsPageQuery: React.FC<CmsPageProps> = (props) => {
    const { data, loading, error } = useQuery<cmsPage>(getCmsPage, {
        variables: { id: props.id },
        ssr: useDeps().env.SSR_GQL,
    });
    if (error) return <ErrorOrOfflineConnected />;
    if (loading)
        return (
            <div style={{ minHeight: '200px' }}>
                <Spinner />
            </div>
        );
    if (!data || !data.cmsPage) {
        console.error('cmsPage data not found for id: %s', props.id);
        return <NotFound />;
    }
    const { title, content, content_heading, meta_title, meta_description, page_layout } = data.cmsPage;
    return (
        <>
            <Helmet>
                <title>{meta_title || title}</title>
                <meta name="description" content={meta_description || ''} />
                <meta property="og:title" content={meta_title || `${title}`} />
                <meta property="og:description" content={meta_description || ''} />
            </Helmet>

            <CmsPageInner
                title={title}
                content_heading={content_heading}
                content={content || `content missing for '${title}', id: '${props.id}'`}
                page_layout={props.page_layout || page_layout!}
            />
        </>
    );
};
