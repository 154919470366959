import React from 'react';

import { RichText } from '../RichText/RichText';
import { PageTitle } from '../Layout/PageTitle';

import classes from './CmsLayout.scss';
import { CmsPageInnerProps } from './CmsPageInner';

export const CmsLayoutDefault: React.FC<CmsPageInnerProps> = (props) => {
    return (
        <>
            {props.content_heading && <PageTitle title={props.content_heading} />}
            <div className={classes.container} data-test-id="CmsPageInnerDefault">
                <RichText content={props.content} />
            </div>
        </>
    );
};
